import { Component } from '@angular/core';
import {ClModalContentData} from '../cl-modal-content-data';

@Component({
  selector: 'app-test-content',
  template: `
              <div id="testContent">
                test app modal component works! -- {{data}}
             </div>
            `
})
export class ClTestModalContentComponent implements ClModalContentData {

  constructor() { }

  data: any;
}

