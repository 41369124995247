import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { Account } from '@mitel/cloudlink-sdk/admin';
import { numberTypes } from '../../app/shared/components/new-number/constants';
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { LocationAdaptorService, Location } from '../services/location-adaptor.service';
import { PhoneServicesService } from '../services/phone-services.service';
import { NewNumberService } from '../services/newNumber.service';
import { Subscription } from 'rxjs';
import { TnAdapterService } from '../services/tn-adapter.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SuccessMessageService } from '../services/success-message.service';
import { TranslateService } from '@ngx-translate/core';
import { BillingImpactDC } from '../modules/boss-api/generated/models';

@Component({
  selector: 'app-toll-free-numbers',
  templateUrl: './toll-free-numbers.component.html',
  styleUrls: ['./toll-free-numbers.component.scss']
})
export class TollFreeNumbersComponent implements OnInit, OnDestroy {
  public numberTypes: any = numberTypes;
  public company: Account;
  public locations: Location[];
  public selectedNumbers: any[] = [];
  public hasSearchCompleted = false;
  private subscriptions: Subscription[] = [];
  private assignAndChargeForm: FormGroup = null;

  constructor(private appSvc: AppUserClaimsService,
    private locAdapterSvc: LocationAdaptorService,
    private phSvc: PhoneServicesService,
    private newNumberSvc: NewNumberService,
    public tnAdapterSvc: TnAdapterService,
    public translateSvc: TranslateService,
    public successMsgSvc: SuccessMessageService) { }

  ngOnInit() {
    this.initForm();
    this.subscriptions.push(this.appSvc.companyChanged.subscribe(company => {
      this.company = company;
      this.getLocationsForAccount(this.company.accountId);
    }));
    this.company = this.appSvc.getCompany();
    this.getLocationsForAccount(this.company.accountId);

    this.subscriptions.push(this.newNumberSvc.selectNewNumber.subscribe((phoneNumber) => {
      if (phoneNumber.selectedNumbers && phoneNumber.selectedNumbers.length) {
        this.selectedNumbers = [...phoneNumber.selectedNumbers];

      } else {
        // need to wrap the following in set timeout because of angular error: Expression has changed after it was checked
        setTimeout(() => {
          this.selectedNumbers = [];
          this.newNumberSvc.resetSelections.next();
        });
      }

    }));

    this.subscriptions.push(this.newNumberSvc.tollFreeSearchCompleted.subscribe((numbers) => {
      if (!_.isEmpty(numbers[0])) {
        this.hasSearchCompleted = true;
      } else {
        this.hasSearchCompleted = false;
      }
    }));
  }

  initForm() {
    this.assignAndChargeForm = new FormGroup({
      'assignAndChargeDropdown': new FormControl(),
    });
  }

  private getLocationsForAccount(accountId: string){
    this.subscriptions.push(this.locAdapterSvc.getLocations(accountId).subscribe((locations: Location[]) => {
      this.locations = locations;
      this.subscriptions.push(this.phSvc.getLocationDC(this.locations[0].value).subscribe((loc) => {
        const dropDownObj = {
          value: loc.uuid,
          displayName: loc.name
        };
        this.assignAndChargeForm.patchValue({assignAndChargeDropdown: dropDownObj});
      }));
    }));
  }

  public placeOrder() {
    const location = this.assignAndChargeForm.get('assignAndChargeDropdown').value.value;
    this.subscriptions.push(this.tnAdapterSvc.placeOrder(this.selectedNumbers, location).subscribe(returnObs => {
      // if (returnObs.status === 'COMPLETE') {
      const message = 'Ordered new toll-free number';
      this.successMsgSvc.sendMessage(message, true);
      this.rebootPage();
    }));
    // this.tnAdapterSvc.placeOrder(this.selectedNumbers, this.company.accountId, this.assignAndChargeForm.get('assignAndChargeDropdown')
    // .value.value);
  }

  rebootPage() {
    this.hasSearchCompleted = false;
    this.newNumberSvc.pageReloaded.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
