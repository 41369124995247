import { Component, OnInit, Input, ElementRef, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-pendo-badge',
  templateUrl: './pendo-badge.component.html',
  styleUrls: ['./pendo-badge.component.css']
})
export class PendoBadgeComponent implements OnInit, AfterViewChecked {
  /**
   * (optional) custom placement of the pendo badge on the page
   * using css property 'right'
   *
   * @memberof PendoBadgeComponent
   */
  @Input() customPendoPosition: { right?: string, zindex?: number };
  @Input() rightPanelOpened: boolean;
  pendoOpened = false;
  helpText: string = 'Help'
  pendoBubble: HTMLElement;
  constructor(private translateSvc: TranslateService,
    private el: ElementRef) { }

  ngOnInit() {
    this.translateSvc.stream('dashboard.help').subscribe(translation => {
      this.helpText = translation
    })
  }

  ngAfterViewChecked(): void {    
    this.pendoBubble = this.el.nativeElement.querySelector('.pendo-resource-center-badge-notification-bubble');    
  }

  openPendo() {
    this.pendoOpened = !this.pendoOpened;
  }

  stylePendoBadge() {
    let styles;
    if (this.rightPanelOpened && this.customPendoPosition) {
      styles = {
        'right': this.customPendoPosition.right ? this.customPendoPosition.right : null,
        'z-index': this.customPendoPosition.zindex ? this.customPendoPosition.zindex : null
      };
      if (this.pendoBubble) {
        this.pendoBubble.style.right = '585';
        this.pendoBubble.style.left = null;
      }
    } else {
      styles = {
        'right': 0,
        'z-index': this.customPendoPosition.zindex ? this.customPendoPosition.zindex : null
      };
    }
    return styles;
  }
}
