
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { numberTypes } from '../shared/components/new-number/constants';
import { LocationAdaptorService, Location } from '../services/location-adaptor.service';
import { PhoneServicesService } from '../services/phone-services.service';
import { NewNumberService } from '../services/newNumber.service';
import { TnAdapterService } from '../services/tn-adapter.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { FormGroup, FormControl } from '@angular/forms';
import { SuccessMessageService } from '../services/success-message.service';
import { TranslateService } from '@ngx-translate/core';
import { BillingImpactDC } from '../modules/boss-api/generated/models';

@Component({
  selector: 'app-local-numbers',
  templateUrl: './local-numbers.component.html',
  styleUrls: ['./local-numbers.component.scss']
})
export class LocalNumbersComponent implements OnInit, OnDestroy {
  public numberTypes: any = numberTypes;
  public company: Account;
  public locations: Location[];
  public showFooter = false;
  private selectedNumbers: any[] = [];
  private subscriptions: Subscription[] = [];
  private assignAndChargeForm: FormGroup = null;

  constructor(private appSvc: AppUserClaimsService,
    private locAdapterSvc: LocationAdaptorService,
    public phSvc: PhoneServicesService,
    private newNumberSvc: NewNumberService,
    public tnAdapterSvc: TnAdapterService,
    public translateSvc: TranslateService,
    public successMsgSvc: SuccessMessageService) { }

  ngOnInit() {
    this.initForm();
    this.subscriptions.push(this.appSvc.companyChanged.subscribe(company => {
      this.company = company;
      this.getLocationsForAccount(this.company.accountId);
    }));

    this.subscriptions.push(this.newNumberSvc.searchExecuted.subscribe(() => {
      this.showFooter = true;
    }));

    this.company = this.appSvc.getCompany();
    this.getLocationsForAccount(this.company.accountId);

    this.subscriptions.push(this.newNumberSvc.selectNewNumber.subscribe((phoneNumber) => {
      // need to wrap the following in set timeout because of angular error: Expression has changed after it was checked
      setTimeout(() => {
        if (phoneNumber && phoneNumber.deselectAll) {
          this.selectedNumbers = [];
        } else if (phoneNumber && phoneNumber.selectAll) {
          this.selectedNumbers = phoneNumber.selectAll;
        } else if (phoneNumber && phoneNumber.selectedRange) {
          this.selectedNumbers = phoneNumber.selectedRange;
        } else if (phoneNumber && phoneNumber.deselectedNumber) {
          _.remove(this.selectedNumbers, (number) => {
            return number.value === phoneNumber.deselectedNumber.value;
          });
        } else if (phoneNumber && phoneNumber.selectedNumbers) {
          this.selectedNumbers.push(...phoneNumber.selectedNumbers);
        }
      });

    }));
  }

  initForm(){
    this.assignAndChargeForm = new FormGroup({
      'assignAndChargeDropdown': new FormControl(),
    });
  }

  private getLocationsForAccount(accountId: string) {
    this.subscriptions.push(this.locAdapterSvc.getLocations(accountId).subscribe((locations: Location[]) => {
      this.locations = locations;
      this.subscriptions.push(this.phSvc.getLocationDC(this.locations[0].value).subscribe((loc) => {
        const dropDownObj = {
          value: loc.uuid,
          displayName: loc.name
        };
        this.assignAndChargeForm.patchValue({assignAndChargeDropdown: dropDownObj});
      }));
    }));
  }

  public placeOrder() {
    const location = this.assignAndChargeForm.get('assignAndChargeDropdown').value.value;
    this.subscriptions.push(this.tnAdapterSvc.placeOrder(this.selectedNumbers, location).subscribe(returnObs => {
      if (returnObs.status === 'COMPLETE') {
        const message = 'Ordered new local numbers';
        // if(this.selectedNumbers.length > 1) message += 's';
        this.successMsgSvc.sendMessage(message, true);
        this.rebootPage();
      } else {
        const message = 'The request was invalid, order not placed';
        this.successMsgSvc.sendMessage(message, true, 'warning');
      }
    }));
    // this.tnAdapterSvc.placeOrder(this.selectedNumbers, this.company.accountId, this.assignAndChargeForm.get('assignAndChargeDropdown').
    // value.value);
  }

  rebootPage() {
    this.showFooter = false;
    this.newNumberSvc.pageReloaded.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
